import React from 'react';
import Privacy from '../components/Privacy';
import ScrollToTop from '../components/ScrollToTop';
import Navbar2 from '../components/Navbar2';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
    return (
        <>
        <ScrollToTop/>
        <Navbar2/>    
        <Privacy/>
        <Footer/>
        </>
    );
};

export default PrivacyPolicy;
