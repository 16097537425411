import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const FooterContainer = styled.footer`
    background-color: #101522;
`;

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px){
        padding-top: 32px;
    }
`;

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`;

export const FooterLinkItems2 = styled.div`
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     align-items: center;
     width: 450px;

    @media screen and (max-width: 820px){
        width: 350px;
    }

    @media screen and (max-width: 420px){
      
        width: 250px;
    
    }
`;

export const FooterLink = styled(Link)`
    color: gold;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover {
        color: #01bf71;
        transition: 0.3s ease-out;
    }
`;

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`;


export const WebsiteRights = styled.small`
    color: #fff;
    margin-top: 100px;
    margin-bottom: 1px;
    justify-content: center;
    display: flex;
    align-items: center;


    @media screen and (max-width: 420px){
      
        margin-top: 60px;
    
    }
`;
