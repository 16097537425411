import styled from 'styled-components';

export const InfoContainer = styled.div`
    color: #fff;
    //#7f7f7f
    // dark oorig. '#010606'
    //light orig. '#f9f9f9' 
    //'#171717' : '#0c0c0c'
    background: ${({lightBg})=> (lightBg ? '#171717' : '#0c0c0c')};
    /* '#e6e6e6' : '#010606')}; */

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
    @media screen and (max-width: 480px){
        padding: 40px 0;
    }
`;
export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 660px;
    //height: 860px;
    width: 100%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
    height: 420px;
    //height: 860px;
    }
    @media screen and (max-width: 480px){
        height: 300px;
    }
`;

export const InfoRow = styled.div`

    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart})=> (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        
        grid-template-areas: ${({imgStart})=> (imgStart ? `'col1' 'col2'` : `'col1 col1'
        'col2 col2'`)};
    }
`;
export const Column1 = styled.div`

    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;

    @media screen and (max-width: 768px){
    margin-bottom: 10px;
    padding: 0 5px;
    }
    @media screen and (max-width: 480px){
        margin-bottom: 5px;
        padding: 0 2px;
    }
`;
export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;

    @media screen and (max-width: 768px){
    margin-bottom: 10px;
    padding: 0 5px;
    }
    @media screen and (max-width: 480px){
        margin-bottom: 5px;
        padding: 0 2px;
    }
`;
export const TextWrapper = styled.div`

    max-width: 540px;
    padding-top: 0;
    padding-bottom: 0px;
    

`;

export const Topline = styled.p`
    /* color: #01bf71; */
    color: #08d4c4;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;

    @media screen and (max-width: 768px){
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 12px;
    }
    @media screen and (max-width: 480px){
        font-size: 14px;
        line-height: 12px;
        margin-bottom: 6px;
    }
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 1.1.%;
    font-weight: 600;
    color: ${({ lightText })=> (lightText ? '#f7f8f#' : '#010606')};

    @media screen and (max-width: 768px){
        font-size: 18px;
        margin-bottom: 12px;
    }
    @media screen and (max-width: 480px){
        font-size: 12px;
        margin-bottom: 6px;
    }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 22px;
    line-height: 24px;
    color: ${({darkText})=> (darkText ? '#010606' : '#f7f8f#')};

    @media screen and (max-width: 768px){
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 1px;
    }
    @media screen and (max-width: 480px){
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 1px;
    }
`;
export const ImgWrap = styled.div`

    max-width: 555px;
    height: 100%;
`;
export const Img = styled.img`
    width: 90%;
    margin: 0 0 10px 0;
    padding-right: 0;

    @media screen and (max-width:  768px){
        width: 75%;
        margin: 0 0 0 0;
        padding-right: 0;
    }
    @media screen and (max-width: 480px){
        width: 40%;
        margin: 0 0 0 0;
        padding-right: 0;
    }
`;