import styled from 'styled-components';

export const HeroContainer = styled.div`
    background: #e6e6e6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    /* padding: 20px 30px; */
    height: 400px;
    /* position: relative;
    z-index: 1; */
    @media screen and (max-width: 768px){
        /* padding: 0 20px; */
        height: 300px;
    }
    @media screen and (max-width: 480px){
        /* padding: 0 10px; */
        height: 230px;
    }
`
export const HeroContent = styled.div`
    /* display: flex; */
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    
`
export const HeroH1 = styled.h1`
    color: #000;
    font-size: 48px;
    text-align: center;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }
    @media screen and (max-width: 480px){
        font-size: 34px;
    }
`
export const HeroP = styled.p`
    color: '#000';
    font-size:24px;
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: 600px;

    @media screen and (max-width: 768px){
        font-size: 20px;
    }
    @media screen and (max-width: 480px){
        font-size: 17px;
    }
`
