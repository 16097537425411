import React from 'react'
import {HeroContainer, HeroP, HeroContent, HeroH1} from './HeroElements';

const HeroSection = () => {

    return (

        <HeroContainer>
            <HeroContent>
            <HeroH1>Midobapps</HeroH1>
            <HeroP>We make mobile games</HeroP>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
