import React from 'react';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems2,
    FooterLink,
    SocialMedia,
    WebsiteRights
    } from './FooterElements';
import {animateScroll as scroll} from 'react-scroll';

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems2>
                                <FooterLink to="/" onClick={toggleHome}>Home</FooterLink>
                                <FooterLink to="/privacy-policy">Privacy policy</FooterLink>
                                
                        </FooterLinkItems2>
                    </FooterLinksWrapper>
                    </FooterLinksContainer>
                
                <SocialMedia>
                        <WebsiteRights>Midobapps, Inc.© {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                </SocialMedia>
            </FooterWrap>
            
        </FooterContainer>
    );
};

export default Footer;
