import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import {IconContext} from 'react-icons/lib';
import {
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks
    } from './NavbarElements';
import {animateScroll as scroll} from 'react-scroll';

const Navbar = ({ toggle }) => {
    console.log('navbar1');
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = ()=> {
        if (window.scrollY >= 80){
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    };

    useEffect(()=>{
        console.log('navbar1');
        window.addEventListener('scroll', changeNav)
        return () => {
        window.removeEventListener('scroll', changeNav)
          }
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
        <IconContext.Provider value= {{ color: 'gold'}}>
          <Nav scrollNav={scrollNav}>
              <NavbarContainer>
                  <NavLogo to='/' onClick={toggleHome}>
                      Midobapps</NavLogo>
                  <MobileIcon onClick={toggle}>
                      <FaBars />
                  </MobileIcon>
                  <NavMenu>
                      <NavItem>
                          <NavLinks 
                          to='about'
                          smooth={true} 
                          duration={500}
                          spy={true}
                          exact='true'
                          offset={-80}
                          >About</NavLinks>
                      </NavItem>
                      <NavItem>
                        <NavLinks to='game'
                          smooth={true} 
                          duration={500}
                          spy={true}
                          exact='true'
                          offset={-80}
                        >Games</NavLinks>
                      </NavItem>
                      <NavItem>
                          <NavLinks to='contact'
                            smooth={true} 
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                          >Contact</NavLinks>
                      </NavItem>
                  </NavMenu>
                  
              </NavbarContainer>
          </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar;
