import icon1White from '../../images/icon1White.png';
import world from '../../images/world.png';


export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Midobapps',
    headLine: '',
    description: ` 
Midobapps is located in Helsinki, Finland.`,
    imgStart: false,
    img: world,
    alt: 'doublephones',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjThree = {
    id: 'game',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Million Dollar Button',
    headLine: '',
    description: 'Coming Soon to the App Store and Android stores!',
    imgStart: true,
    img: icon1White,
    alt: 'mrbutton',
    dark: true,
    primary: true,
    darkText: false
}
export const homeObjFour = {
    id: 'contact',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Midobapps',
    headLine: '',
    description: 'Contact: support@midobapps.com',
    imgStart: false,
    dark: true,
    primary: true,
    darkText: false
}

