import React from 'react';
import {IconContext} from 'react-icons/lib';
import {
    Nav, 
    NavbarContainer, 
    NavLogo
    } from './NavbarElements2';
import {animateScroll as scroll} from 'react-scroll';

const Navbar2 = () => {
    React.useEffect(() => {
        console.log('navbar2');
        
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
        <IconContext.Provider value= {{ color: 'gold'}}>
          <Nav>
              <NavbarContainer>
                  <NavLogo to='/' onClick={toggleHome}>
                      Midobapps</NavLogo>

              </NavbarContainer>
          </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar2;
