import React from 'react'
import {HeroContainer, HeroContent, HeroH1, HeroP, HeroH2} from './privacyElements';

const Privacy = () => {
    return (
        <HeroContainer>
        <HeroContent>
            <HeroH1>Privacy policy</HeroH1>
            <HeroH2>1. Policy scope and purpose,  Who We Are, changes to the policy</HeroH2>
            <HeroP>We are Midobapps, Inc.(“we”, “our”, “us”, “company”). We do not sell, trade, or rent Users’ personal identification information to third parties. 
                
                This privacy policy ("Privacy Policy") describes how we collect, use and store, maintain and protect the information provided by you ("You" or "User") to us through using products, 
                websites, services or (“Service”) including mobile apps. This policy applies to all our Services unless the Service has an overriding policy. 
                Other privacy policies may apply on other platforms affiliated to our games. It is strongly advised that you carefully review this privacy policy before using our Services. 
                By using our Apps or Sevices or any of the features of our Apps, you assent to the contents of this privacy policy. If you do not agree to this privacy policy, 
                please do not use our Apps or any of their features or provide any information through our Apps. We respect our Users' privacy and continuously work with our partners to 
                secure our users’ personal information.
    
We may make changes to this privacy policy from time to time. When this happens, we will revise the update date at the end of this privacy policy. 
Please see this privacy policy periodically to keep up to date of any revisions to this privacy policy. 
Your continued use of the App signifies that you accept of any changes made to this privacy policy
    
</HeroP>
<HeroH2>2. Collected information</HeroH2>

<HeroP>a) Personal Information</HeroP>
<HeroP>
By signing up and playing our games or using our services you agree and give us your consent to show advertisements to you in our services. 
Our advertising partners may use the Identifier for Advertisers (IDFA). Advertisers use this to track data so they can deliver customized advertising. 
The IDFA is used for tracking and identifying a user without revealing personal information. Our advertising partners may collect certain information from apps, including: 
IP address, non-user related crash logs, User-associated performance data, Performance data may also be used for displaying ads, A Device ID such as the device's advertising 
identifier or other app-bounded device identifiers, which may be used for the purpose of third-party advertising and analytics. Advertising data, Other user product interactions like app launch taps, 
and interaction information, like video views, may be used to improve advertising performance.
We collect different types of personal information from you depending on how you interact with our Services. Personal information is information about an individual 
from which that person can be identified. 

NOTE: Some of our Apps is not intended for minors. We do not knowingly collect, use or disclose Personal Information of Users that are not in the majority of age in their 
country or state of residence.

At our discretion, we may collect personally identifiable information from you when using our Apps and their features. Personally Identifiable Information may include, 
though is not limited to email address. This personal information we collect is given to us by you through registering for an account with email and password.
Winning(user wins) the jackpot is a special case where the personal information we collect may vary. 
This personal data may include contact and transaction information you provide for the purposes of sending payments to you, such as the bank information and other information 
associated with payment transactions, geographic location, and our legal obligations in that jurisdiction.

We do not sell, trade, or rent this information to third parties. Our advertising partners can, however, adhere to their own privacy policy which you may or may not accept. 
Users may find advertising or other content on our Services that link to the sites and services of our partners, suppliers, advertisers, sponsors, 
licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or 
from our Services. In addition, these sites or services, including their content and links, may be constantly changing. 
These sites and services may have their own privacy policies and customer service policies. 
Browsing and interaction on any other website, including websites which have a link to our Services, is subject to that website’s own terms and policies.
</HeroP>
<HeroP>b) Non-personal Information</HeroP>
<HeroP>
When you use our Apps and Services, we may at our discretion, automatically collect and store the following information: click-through statistics, 
your country or city-specific coarse location data of your IP address, the operating system you are using, the model of device used to, the date and time you use our Apps. 
We also may create a game-specific ID for you when you use the Services.
This information is only used in aggregate form for statistical purposes. This information makes it possible for us to determine whether the App is working properly. 
This information is not used to identify particular users. Non-personal Information may include a username (‘nickname’ of the user). 
This information we collect is given to us by you through registering for an account with email and password. 
Using the Service gives us permission to share the username in advertising and publicity purposes in any media, without further compensation, unless prohibited by law.
</HeroP>
<HeroH2>3. Security of your personal information</HeroH2>
<HeroP>
We take reasonable and necessary actions to secure all Personal Information collected by us through the App. In addition, we take reasonable and necessary steps to ensure that all 
third parties to whom we transfer any data will provide sufficient protection for the Personal Information they receive from us.

To help us keep your Personal information safe, you should: keep your device locked; not share any passwords or other log-in data with others; and change your password regularly. 
We are not responsible for any breaches of user profiles which may lead to lost or stolen emails or passwords. Should User’s emails or passwords be inadvertently disclosed, 
users should change their password as soon as possible.  
</HeroP>
<HeroH2>4. Why we collect personally identifiable information and where it is used</HeroH2>
<HeroP>
The personal information(email) you provide will allow us to register you as a user of our Services, notify you about changes to our terms or Privacy Policy, 
and contact you if you win the jackpot and to inform you of any updates and enhancements to the App. 
</HeroP>
<HeroH2>5. Disclosure of your personal information</HeroH2>
<HeroP>
we may need to share your personal information with others, including in the following circumstances.

We may need to disclose your Personal Information as required and permitted by law. This may be required of us when, for example we need to respond to a subpoena or court order or 
we have reason to believe you are violating your agreement with us. We may also disclose your Personal Information should we have reason to believe that you are harming or 
interfering with the Company, others users of this App, the general public or other third parties.

We may share your personal information with third party service providers who perform various functions to enable us to provide our services and help us operate our business, 
such as app and website design, sending email, fraud detection and prevention, customer care, payment processing(payment of the jackpot), or performing analytics.

You may be asked to share personal information with our affiliates and/or partners. In some of those cases, we may act as co-controllers of your personal information, 
depending on the circumstances.

Please be noted that you may also receive information from third parties with whom we have shared your Personal Information with. 
We cannot control or guarantee on their behalf that they adhere to a similar privacy policy to this and go to the same lengths to secure your Personal Information.

If we sell, merge, or transfer any part of our business, we may be required to share your information. 
 
</HeroP>
<HeroH2>6. Managing your personal information</HeroH2>
<HeroP>
When asked for your personal information, we give you an option to decline any further offers or information about the App and services and products directly related to the App.

</HeroP>
<HeroH2>7. Who collects your personal information</HeroH2>
<HeroP>
The Personal Information you provide to us is shared with the Company, its affiliates and marketing partners, unless otherwise specified. Our affiliates and marketing partners 
may thus communicate with you using the Personal Information you have shared. Please refer to the privacy policies of our advertising partners for more information. 
Our advertisers include the following though is not limited to:

Google’s AdMob: https://policies.google.com/privacy
Facebook: https://www.facebook.com/about/

If you wish to not share your personal information, please do not use our Apps or services associated with the App.
 
</HeroP>
<HeroH2>8. Personal Information and children</HeroH2>
<HeroP>
Our Apps is intended for adults who have reached the age of majority according to the relevant jurisdiction of their country and/or state. 
The Company does not knowingly collect Personal Information from people who have not reached the age of majority according to their relevant jurisdiction. 
We protect the privacy of children and we respect the laws in that matter, including the United States’ Children’s Online Privacy Protection Act (COPPA).

If you are the parent or guardian of a minor that has provided Personal Information to us, please contact us at support@midobapps.com. By doing so, 
we can take the necessary steps to destroy such information and ensure such Personal Information is not shared any further by us. We cannot, however, 
fully control or demand that our marketing partners or our other affiliates stop sharing or using Personal Information already shared with them, as they may not adhere to a 
similar privacy policy to ours.

</HeroP>
<HeroH2>9. Deleting or correcting your Personal Information</HeroH2>
<HeroP>
If you are concerned about the way that your Personal Information is used by us, our marketing partners or other affiliates or would like to correct the Personal 
Information you have shared or have it deleted, please contact us at support@midobapps.com. You can usually go directly into the Player Info tab in the mobile 
application to take these actions(‘delete account’, ‘update email’ etc.). 

</HeroP>
<HeroH2>10. EU General Data Protection Regulation (GDPR) </HeroH2>
<HeroP>
EU data subjects have eight rights with respect to your personal information that we collect and process: The Right to Information, The Right of Access, The Right to Rectification, 
The Right to Erasure,The Right to Restriction of Processing, The Right to Data Portability, The Right to Object and The Right to Avoid Automated Decision-Making.
We respond to all requests we receive from individuals in the EAA wishing to exercise their Data Protection Regulation and data protection laws. 
https://eur-lex.europa.eu/eli/reg/2016/679/2016-05-04
For example you may request access to, correction of, or deletion of your personal information. 
You can usually go directly into the Player Info tab in the mobile application to take these actions(‘delete account’, ‘update email’ etc.).

However, to file a request or take another action on one of your rights, please contact us at the contact details provided. 
Accessing your personal information is free (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, 
repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances. 

We may need to confirm your identity and/or ensure your right to access your personal information (or to exercise any of your other rights). 

We respond to all legitimate requests within one month. Occasionally it may take us longer than a month depending on for example the complexity of the request.

</HeroP>
<HeroH2>11. Contacting us</HeroH2>
<HeroP>
If you have questions, requests, comments or complaints regarding this privacy policy or your Personal Information, please contact us at:

support@midobapps.com

We may make changes to this privacy policy from time to time. When this happens, we will revise the update date at the end of this privacy policy. 
Please see this privacy policy periodically to keep up to date of any revisions to this privacy policy. 
Your continued use of the App signifies that you accept any changes made to this privacy policy.
</HeroP>
<HeroP>
Last Modified: June 4, 2022
</HeroP>

     


        </HeroContent>
    </HeroContainer>
    );
};

export default Privacy;
