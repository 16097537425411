import React from 'react';
import {
    SidebarCointainer, 
    Icon, CloseIcon, 
    SidebarWrapper, 
    SidebarMenu, 
    SidebarLink, 
    SideBtnWrap
    } from './SidebarElements';
import {IconContext} from 'react-icons/lib';
// import SocialFollow from '../../SocialFollow';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <IconContext.Provider value= {{ color: 'gold'}}>
        <SidebarCointainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about"onClick={toggle}>About</SidebarLink>
                    <SidebarLink to="game"onClick={toggle}>Games</SidebarLink>
                    {/* <SidebarLink to="install"onClick={toggle}>Install</SidebarLink> */}
                    <SidebarLink to="contact"onClick={toggle}>Contact</SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    {/* <SocialFollow/> */}
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarCointainer>
        </IconContext.Provider>
    )
}

export default Sidebar;
