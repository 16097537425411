import styled from 'styled-components';

export const HeroContainer = styled.div`
    background: #fff;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    /* height: auto; */
    /* position: relative; */
`
export const HeroContent = styled.div`
    
    /* height: auto;
    position: relative; */
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    //align-items: center;

    @media screen and (max-width: 768px){
        padding: 30px 2px;
    }
    @media screen and (max-width: 480px){
        padding: 8px 2px;
        
    }
`
export const HeroH1 = styled.h1`
    color: #000;
    font-size: 40px;
    text-align: center;
    margin-bottom: 30px;

    @media screen and (max-width: 768px){
        font-size: 30px;
    }
    @media screen and (max-width: 480px){
        font-size: 26px;
    }
`
export const HeroH2 = styled.h1`
    margin: 20px;
    color: #000;
    font-size:26px;
    text-align: justify;
    font-weight:normal;
    padding:0 15px;
    @media screen and (max-width: 768px){
        font-size: 22px;
        margin: 12px;
        padding:0 10px;
    }
    @media screen and (max-width: 480px){
        font-size: 18px;
        margin: 10px;
        padding:0 5px;
    }
`
export const HeroP = styled.p`
    margin: 20px;
    color: #000;
    font-size:20px;
    text-align: justify;
    padding:0 15px;
    @media screen and (max-width: 768px){
        font-size: 18px;
        margin: 12px;
        padding:0 10px;
    }
    @media screen and (max-width: 480px){
        font-size: 16px;
        margin: 10px;
        padding:0 5px;
    }
`